import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { onEnterHandler } from '../../../utils';
import { navigate } from '../../../services/NavigationService';
import { IJson, openPage } from '../../../utils/OpenPage';
import { RootState } from '../../../stores/reducers/index';
import { speakTTS } from '../../../services/TTSService'; // needs for further invest-n

import './CometChargeSettings.scss';

type OTTCometSettingProps = {
  clearOTTSettingsData: () => void;
};

const CometChargeSettings = (props: OTTCometSettingProps) => {
  const { clearOTTSettingsData } = props;

  const settingsData = useSelector((state: RootState) => state.OTTSettings.settingsData);
  const buttonStyles = useSelector((state: RootState) => state.config.styles['button']);
  const settingsStyles = useSelector((state: RootState) => state.config.styles['settings']);
  const fontStyles = useSelector((state: RootState) => state.config.styles['font']['face']);
  const navIdx = useSelector((state: RootState) => state.common.activeNavIdx);

  const textAnnounceRef = useRef<number>(0);
  const textAnnounceTimeout = useRef<any>(null);

  const history = useHistory();

  const keyDownHandler = (e: React.KeyboardEvent, item: any): void => {
    onEnterHandler(e,
      () => navigate(
        history,
        openPage(item as unknown as IJson, { navIdx }),
        clearOTTSettingsData,
        { lastFocusedElement: document.activeElement?.id }
      )
    );
  };

  useEffect(() => {
    const text = settingsData[0]?.description;

    if (text && !textAnnounceRef.current) {
      textAnnounceTimeout.current = setTimeout(() => speakTTS(text), 1500);
      textAnnounceRef.current++;
    }

    return () => {
      if (textAnnounceTimeout.current !== null) {
        clearTimeout(textAnnounceTimeout.current);
      }
    };
    // eslint-disable-next-line
  }, [settingsData.length]);

  const initialButtonStyles = {
    fontFamily: `${buttonStyles?.fontFace?.fontFamily || fontStyles.fontFamily}`,
    fontSize: `${buttonStyles.textFontSize}px`,
    color: `${buttonStyles.textColor}`,
    backgroundColor: `${buttonStyles.colorOff}`,
    border: `${buttonStyles.borderSize}px solid ${buttonStyles.borderColorOff}`,
  };

  const onButtonFocused = (e) => {
    e.target.style.border = `${buttonStyles.borderSize}px solid ${buttonStyles.borderColorOn}`;
    e.target.style.backgroundColor = `${buttonStyles.colorOn}`;
    e.target.style.color = `${buttonStyles.textFocusColor}`;
  };

  const onButtonBlur = (e) => {
    e.target.style.border = `${buttonStyles.borderSize}px solid ${buttonStyles.borderColorOff}`;
    e.target.style.backgroundColor = `${buttonStyles.colorOff}`;
    e.target.style.color = `${buttonStyles.textColor}`;
  };

  return (
    <div className='settings-container'>
      <div className='main'>
        <div
          // eslint-disable-next-line
          role=''
          aria-label={' '}
          className='description'
          tabIndex={-1}
          data-tv-focus-up='#navigationSelectedItem'
          data-tv-focus-down='.otttext-button.btn-0'
          style={{
            color: `${settingsStyles.textColor}`,
            fontFamily: `${settingsStyles.focusFontFace.fontFamily}`,
          }}
        >
          <p
            style={{border: `${buttonStyles.borderSize}px solid transparent`}}
            className='description-text'
          >
            {settingsData[0]?.description}
          </p>
        </div>
        <div className="buttons-container">
          {settingsData.filter(item => item.title).map((button, index) => (
            <button
              // eslint-disable-next-line
              role=''
              aria-label={button.title}
              key={index}
              data-tv-focus-up={(index === 0) ? '#navigationSelectedItem' : '' }
              className={`otttext-button btn-${index}`}
              style={initialButtonStyles}
              onKeyDown={(e) => keyDownHandler(e, button)}
              onFocus={onButtonFocused}
              onBlur={onButtonBlur}
              id={`${button.title?.replaceAll(' ', '-')}-btn`}
            >
              {button.title}
            </button>
          ))
          }
        </div>
      </div>
    </div>
  );
};

export default CometChargeSettings;
