//  https://github.com/video-dev/hls.js/blob/HEAD/docs/API.md
import { IPlayer } from '../InterfacesAndTypes';
import { hlsConfig } from './hlsConfig';
import { getMilliseconds } from '../../../utils/time';

declare const Hls: any;

export const hlsPlayer: IPlayer = {
  player: null,
  playerEl: null,
  playerName: 'hls',
  state: 'NONE',

  states: {
    none: 'NONE',
    idle: 'IDLE',
    buffering: 'BUFFERING',
    ready: 'READY',
    playing: 'PLAYING',
    pause: 'PAUSED',
  },

  initialize(el, DrmParam) {
    if (this.player) {
      this.player?.detachMedia();
    }

    const getDrmExtendedConfig = () => ({
      ...hlsConfig,
      emeEnabled: true,
      widevineLicenseUrl: DrmParam.LicenseServer,
      // eslint-disable-next-line
      licenseXhrSetup: (xhr, url) => {
        xhr.withCredentials = true; // do send cookies
        if (!xhr.readyState) {
          // Call open to change the method (default is POST) or modify the url
          xhr.open('POST', url, true);
          // Append headers after opening
          xhr.setRequestHeader('Content-Type', 'application/octet-stream');
          xhr.setRequestHeader('customdata', `${DrmParam.CustomData}`);
        }
      },
    });
    const extendedConfig = DrmParam?.CustomData ? {...getDrmExtendedConfig()} : hlsConfig;

    this.player = new Hls(extendedConfig);
    this.playerEl = el;

    this.setState('IDLE');
  },

  play() {
    if (this.playerEl) {
      const playPromise = this.playerEl?.play();

      if (playPromise !== undefined) {
        playPromise
          .then(() => this.setState('PLAYING'))
          .catch((e) => {
            console.log('Error', e);
          });
      }
    }
  },

  pause() {
    this.playerEl?.pause();
    this.setState('PAUSED');
  },

  stop() {
    this.player?.stopLoad();
  },

  seekTo(milliseconds, sc?, ec?) {
    const successCallback = sc || (() => true);
    const errorCallback = ec || (() => false);
    const secondsFixed = milliseconds / 1000;

    try {
      this.playerEl.currentTime = +secondsFixed.toFixed(6);
      successCallback();
    } catch (err) {
      errorCallback();
      console.log(err);
    }
  },

  open(url, sc, ec, arrCbs): void {
    this.player?.loadSource(url);

    if (arrCbs?.length) {
      arrCbs.forEach(([type, cb]) => this.playerEl?.addEventListener(type, cb));
    }
  },

  prepare(sc, ec, cbsForListeners, cbsForVideoEl): void {
    this.player?.attachMedia(this.playerEl);

    // set listeners
    if (this.playerEl && cbsForVideoEl?.length) {
      cbsForVideoEl.forEach(([type, Cb]) => this.playerEl.addEventListener(type, Cb));
    }
    if (cbsForListeners?.length) {
      this.setListeners(cbsForListeners);
    }
  },

  close(arrCbs, cbsForListeners): void {
    if (this.playerEl && arrCbs?.length) { // Remove Listeners from <video /> Element
      arrCbs.forEach(([type, cb]) => this.playerEl.removeEventListener(type, cb));
    }

    if (this.player && cbsForListeners?.length) { // Remove Listeners from player
      cbsForListeners.forEach(([key, cb]) => this.player.off(Hls.Events[key], cb));
    }

    this.player?.detachMedia();
    this.player?.destroy();
    this.player = null;
  },

  suspend() {
    this?.pause();
    this.setState('PAUSED');
  },

  restore() {
    this?.play();
    this.setState('PLAYING');
  },

  setListeners(listenersArr): void {
    listenersArr.forEach(([key, cb]) => this.player?.on(Hls.Events[key], cb));

    // <<FOR DEBUGGING && FUTURE DEVELOPMENT>>
    // const events = [Hls.Events.MEDIA_ATTACHING,
    //   Hls.Events.MEDIA_ATTACHED,
    //   Hls.Events.MEDIA_DETACHING,
    //   Hls.Events.MEDIA_DETACHED,
    //   Hls.Events.BUFFER_RESET,
    //   Hls.Events.BUFFER_CODECS,
    //   Hls.Events.BUFFER_CREATED,
    //   Hls.Events.BUFFER_EOS,
    //   Hls.Events.BUFFER_FLUSHING,
    //   Hls.Events.BUFFER_FLUSHED,
    //   Hls.Events.BUFFER_APPENDED,
    //   Hls.Events.MANIFEST_LOADING,
    //   Hls.Events.MANIFEST_LOADED,
    //   Hls.Events.MANIFEST_PARSED,
    //   Hls.Events.LEVEL_SWITCHING,
    //   Hls.Events.LEVEL_SWITCHED,
    //   Hls.Events.LEVEL_LOADING,
    //   Hls.Events.LEVEL_LOADED,
    //   Hls.Events.LEVEL_UPDATED,
    //   Hls.Events.LEVELS_UPDATED,
    //   Hls.Events.AUDIO_TRACKS_UPDATED,
    //   Hls.Events.AUDIO_TRACK_SWITCHING,
    //   Hls.Events.AUDIO_TRACK_SWITCHED,
    //   Hls.Events.AUDIO_TRACK_LOADING,
    //   Hls.Events.AUDIO_TRACK_LOADED,
    //   Hls.Events.SUBTITLE_TRACKS_UPDATED,
    //   Hls.Events.SUBTITLE_TRACK_SWITCH,
    //   Hls.Events.SUBTITLE_TRACK_LOADING,
    //   Hls.Events.SUBTITLE_TRACK_LOADED,
    //   Hls.Events.ERROR,
    //   Hls.Events.DESTROYING];

    // events.map(e => {
    //   this.player?.on(e, () => {
    //     console.log('HLS e', e);
    //   });
    // });
  },

  showCaptions(neededCCTrack, setCurrentCueCb): void {
    if (this.playerEl?.textTracks?.length) {
      this.playerEl.addEventListener('loadedmetadata', () => {
        if (this.playerEl.textTracks.length) {
          return;
        } else {
          this.playerEl.addTextTrack('captions', 'English', 'en');
        }
      });

      for (let i = 0; i < this.playerEl.textTracks.length; i++) {
        if (Object.prototype.hasOwnProperty.call(this.playerEl.textTracks[i], 'textTrack1')) {
          neededCCTrack = this.playerEl.textTracks[i];
        }
      }

      neededCCTrack.oncuechange = function(event) {
        let cue, resultingCue;

        // warning: TextTrackCueList is an iterable object (not array), you can't use `forEach` method for it
        // see: https://javascript.info/iterable
        for (const item of event.currentTarget.activeCues) {
          cue += `${item.text} `;
        }

        if (cue) {
          resultingCue = cue.slice(9, -1);
          setCurrentCueCb(resultingCue);
        }
      };
    }
  },

  getVideoBandwidth() {
    let videoBandwidth;

    try {
      videoBandwidth = this.player?.abrController?.bwEstimator?.getEstimate();
    } catch (e) {
      console.log('Error videoBandwidth: ', e);
    }

    return videoBandwidth ? Math.floor(videoBandwidth).toString() : null;
  },

  setState(state): any {
    this.state = state;
  },

  getState(): any {
    return this.state;
  },

  getPlayerIsLive(): any {
    return !!this.player;
  },

  getDuration() {
    const currentDurationInSec = this.playerEl?.duration;

    return getMilliseconds(currentDurationInSec);
  },

  getCurrentTime() {
    const currentTimeInSec = this.playerEl?.currentTime;

    return getMilliseconds(currentTimeInSec);
  },

  getPlayerName() {
    return this.playerName;
  },
};
