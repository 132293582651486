import React, { useEffect, useState } from 'react';
import { CCStyles } from '../../../utils/captions';

type CCProps = {
  currentCue: string;
  fontColor?: number;
  fontSize?: number;
  fontOpacity?: number;
  bgColor?: number;
  bgOpacity?: number;
  edgeType?: number;
  edgeColor?: number;
  windowColor?: number;
  windowOpacity?: number;
  fontStyle?: number;
  isLiveOverlayOpen: boolean;
};

const ClosedCaptions = React.memo(function ClosedCaptions(props: CCProps) {
  const {
    currentCue,
    fontColor = 0,
    fontSize = 0,
    fontOpacity = 0,
    bgColor = 0,
    bgOpacity = 0,
    edgeType = 0,
    edgeColor = 0,
    windowColor = 0,
    windowOpacity = 0,
    fontStyle = 0,
    isLiveOverlayOpen,
  } = props;

  const [CCWidth, setCCWidth] = useState<number>(0);
  const [CCHeight, setCCHeight] = useState<number>(0);

  const useTextStroke = edgeType === 3;

  useEffect(() => {
    setCCWidth(document.getElementById('CCText')?.offsetWidth || 0);
    setCCHeight(document.getElementById('CCText')?.offsetHeight || 0);
  }, [currentCue]);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        position: 'absolute',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          zIndex: 101,
          position: 'absolute',
          bottom: `${isLiveOverlayOpen ? '41vh' : '6px'}`,
          backgroundColor: CCHeight && CCWidth && !!currentCue && CCStyles.windowColor[windowColor],
          width: `${CCWidth + 44}px`,
          height: `${CCHeight + 42}px`,
          opacity: CCStyles.windowOpacity[windowOpacity],
        }}
      />
      <div
        style={{
          zIndex: 102,
          position: 'absolute',
          bottom: `${isLiveOverlayOpen ? '43vh' : '25px'}`,
          backgroundColor: CCHeight && !!currentCue && CCWidth && CCStyles.backgroundColor[bgColor],
          width: `${CCWidth + 4}px`,
          height: CCHeight,
          opacity: CCStyles.backgroundOpacity[bgOpacity],
        }}
      />
      <span
        style={{
          zIndex: 103,
          position: 'absolute',
          bottom: `${isLiveOverlayOpen ? '43vh' : '25px'}`,
          color: CCStyles.fontColor[fontColor],
          opacity: CCStyles.fontOpacity[fontOpacity],
          fontFamily: CCStyles.fontStyle[fontStyle],
          fontSize: CCStyles.fontSize[fontSize],
          textShadow: `${!useTextStroke && CCStyles.edgeType[edgeType]} ${CCStyles.edgeColor[edgeColor]}`,
          // @ts-ignore
          textStroke: `${useTextStroke && '2px'} ${CCStyles.edgeColor[edgeColor]}`,
          // For Tizen-3 && MacOS: if the app haven't launched, replace textStroke with WebkitTextStroke
          // WebkitTextStroke: `${useTextStroke && '2px'} ${CCStyles.edgeColor[edgeColor]}`,
        }}
        id="CCText"
      >
        {currentCue}
      </span>
    </div>
  );
});

export default ClosedCaptions;
