import React from 'react';

import AVPlayWrapper from './AVPlayWrapper/AVPlayWrapper';
import HLSWrapper from './HLSWrapper/HLSWrapper';
import JWPlayerWrapper from './JWPlayerWrapper/JWPlayerWrapper';

import './PlayerWrapper.scss';

type PlayerProps = {
  playerName: string;
  onStreamPlay?: any;
  onStreamPause?: any;
  onStreamCompleted: any;
  onCanPlay?: any;
  onParsingMetadata?: any;
  changeCCFontStyle?: any;
  onBufferingComplete?: any;
  onCurrentPlaytime?: any;
  onDrmEvent?: any;
  onError?: any;
  onSubtitleChange?: any;
  onSuccessPrepared?: any;
  onSuccessLoad: any;
  onFailedLoad: any;
  destroyDaiStream?: any;
  neededCCTrack?: any;
  setCurrentCue?: any;
  seekToSuccessCb?: any;
  checkPlayStatePosition?: any;
  cleanUpCb?: any;
  onPausedBeforeAd?: any;
  typeContent: string;
  onDurationChange?: any;
  drm?: any;
};

const PlayerWrapper = ({
  playerName,
  onStreamPlay,
  onStreamPause,
  onStreamCompleted,
  onCanPlay,
  onParsingMetadata,
  changeCCFontStyle,
  onBufferingComplete,
  onCurrentPlaytime,
  onDrmEvent,
  onError,
  onSubtitleChange,
  onSuccessPrepared,
  onSuccessLoad,
  onFailedLoad,
  destroyDaiStream,
  neededCCTrack,
  setCurrentCue,
  seekToSuccessCb,
  checkPlayStatePosition,
  cleanUpCb,
  onPausedBeforeAd,
  onDurationChange,
  typeContent,
  drm,
}: PlayerProps, ref: any) => {
  const getPlayer = () => {
    switch (playerName) {
      case 'avplay':
        return (
          <AVPlayWrapper
            onStreamPlay={onStreamPlay}
            onStreamPause={onStreamPause}
            onStreamCompleted={onStreamCompleted}
            onCanPlay={onCanPlay}
            onParsingMetadata={onParsingMetadata}
            changeCCFontStyle={changeCCFontStyle}
            onBufferingComplete={onBufferingComplete}
            onCurrentPlaytime={onCurrentPlaytime}
            onDrmEvent={onDrmEvent}
            onError={onError}
            onSubtitleChange={onSubtitleChange}
            onSuccessLoad={onSuccessLoad}
            onFailedLoad={onFailedLoad}
            seekToSuccessCb={seekToSuccessCb}
            checkPlayStatePosition={checkPlayStatePosition}
            cleanUpCb={cleanUpCb}
            onPausedBeforeAd={onPausedBeforeAd}
            drm={drm}
          >
            {<object
              id='av-player'
              className='player'
              type='application/avplayer'
              ref={ref}
              tabIndex={-1}
              data=''
              aria-label='VOD player'
            />}
          </AVPlayWrapper>
        );
      case 'hls':
        return (
          <HLSWrapper
            onStreamPlay={onStreamPlay}
            onStreamPause={onStreamPause}
            onStreamCompleted={onStreamCompleted}
            onCanPlay={onCanPlay}
            onParsingMetadata={onParsingMetadata}
            changeCCFontStyle={changeCCFontStyle}
            onSuccessPrepared={onSuccessPrepared}
            onSuccessLoad={onSuccessLoad}
            onFailedLoad={onFailedLoad}
            destroyDaiStream={destroyDaiStream}
            onError={onError}
            neededCCTrack={neededCCTrack}
            setCurrentCue={setCurrentCue}
            checkPlayStatePosition={checkPlayStatePosition}
            /* below are props for VOD player */
            seekToSuccessCb={seekToSuccessCb}
            cleanUpCb={cleanUpCb}
            onPausedBeforeAd={onPausedBeforeAd}
            onCurrentPlaytime={onCurrentPlaytime}
            onBufferingComplete={onBufferingComplete}
            onDurationChange={onDurationChange}
            typeContent={typeContent}
            drm={drm}
          >
            {
              <video
                className='player'
                id='hlsPlayer'
                ref={ref}
                tabIndex={1}
                aria-label=''
                // eslint-disable-next-line
                role=''
              />
            }
          </HLSWrapper>
        );

      case 'jwplayer':
        return <JWPlayerWrapper
          onStreamPlay={onStreamPlay}
          onStreamPause={onStreamPause}
          onStreamCompleted={onStreamCompleted}
          onCanPlay={onCanPlay}
          onParsingMetadata={onParsingMetadata}
          onSuccessPrepared={onSuccessPrepared}
          onSuccessLoad={onSuccessLoad}
          onFailedLoad={onFailedLoad}
          onError={onError}
          destroyDaiStream={destroyDaiStream}
          neededCCTrack={neededCCTrack}
          setCurrentCue={setCurrentCue}
          checkPlayStatePosition={checkPlayStatePosition}
          /* below are props for VOD player */
          seekToSuccessCb={seekToSuccessCb}
          onPausedBeforeAd={onPausedBeforeAd}
          cleanUpCb={cleanUpCb}
          onCurrentPlaytime={onCurrentPlaytime}
          onBufferingComplete={onBufferingComplete}
          typeContent={typeContent}
          drm={drm}
        >
          <div
            className='player'
            id='jwPlayer'
            ref={ref}
            tabIndex={1}
            aria-label=''
            // eslint-disable-next-line
            role=''
          />
        </JWPlayerWrapper>;
      default:
        return null;
    }
  };

  return getPlayer();
};

export default React.forwardRef(PlayerWrapper);
